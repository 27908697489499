import jwtDecode from 'jwt-decode';
import React from "react";
import { Cookies } from "react-cookie";
//import { ErrorBoundary } from "react-error-boundary";
import { Redirect, Route, useParams } from "react-router-dom";
import { AuthenticationError } from '../../components/authentication-error';

interface PropTypes {
    component: React.FC | React.ComponentType;
    path?: string;
    exact?: boolean;
}

const getTokenData = (access_token: string) => {
    try {
        const decoded = jwtDecode(access_token);
        if (!decoded) {
            return null;
        }
        return decoded;
    } catch (e) {
        console.log(e)
        return null
    }
};

const PrivateRoute = ({ component, ...args }: PropTypes): (JSX.Element) => {
    const cookies = new Cookies();

    const newAccessToken: string | undefined = window.location.search.split("&").find(a => a.includes("access_token"))?.split("=")[1]
    
    if(newAccessToken) {
        cookies.set('access_token', newAccessToken)
        window.location.replace("/")
    }
    
    const access_token = cookies.get('access_token')
    const access_token_data = access_token && getTokenData(access_token)

    if (process.env.NODE_ENV !== "development" && (!access_token_data || (new Date().getTime() > access_token_data.expires))) {
        return <AuthenticationError isUserExpired={!!access_token_data}/>
    }

    return <Route
        component={component}
        {...args}
    />  ;
};

export default PrivateRoute;
