import React, { LegacyRef, MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import Markdown from 'markdown-to-jsx';
import { RequirementType } from '../../../interfaces/requirements';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import './index.scss';
import api from '../../../api';
import { RequirementsContext } from '../../../context/requirements/RequirementsContext';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface PropTypes extends RouteComponentProps{
    requirement: RequirementType;
}

const Signature = ({history, requirement}: PropTypes) => {
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true)
    const signCanvas = useRef() as MutableRefObject<any>;
    const {errorMessage, loading, submitRequirement} = useContext(RequirementsContext);
    const { enqueueSnackbar } = useSnackbar()

    const cleanSignature = () => { 
        signCanvas.current.clear();
        if (signCanvas.current.isEmpty()){
            setIsSignatureEmpty(true);
        };
    };

    const saveSignature = async () => {
        if (signCanvas.current.isEmpty()){
            return;
        };
        const data = {
            signature: signCanvas.current.toDataURL()
        }
        const formData = new FormData()
        await signCanvas.current.getCanvas().toBlob(async (blob: any) => {
            formData.append('attachments', blob, 'signature.png')
            // POST to server with your preferred requests library
            data && formData.append("data", JSON.stringify(data))

            const success = await submitRequirement(requirement, formData)
            console.log(success)
            if(success) {
                enqueueSnackbar("Requerimiento completado con éxito", {
                    variant: "success"
                })
                history.push('/')
            }
        })
    };

    const checkSignature = () => {
        if (signCanvas.current.isEmpty()){
            setIsSignatureEmpty(true);
        }
        else {
            setIsSignatureEmpty(false);
        }
    };

    const fillParameters = (text: string) => {
        return text
            .replace("{{contact.first_name}}", requirement.contact?.first_name || "")
            .replace("{{contact.last_name}}", requirement.contact?.last_name || "")
            .replace("{{contact.middle_name}}", requirement.contact?.middle_name || "")
            .replace("{{contact.email}}", requirement.contact?.email || "")
    }

    return (
        <div className="signature">
            { requirement.template.instructions &&(
            <Markdown>
                {requirement.template.instructions}
            </Markdown>
           )}
           <Divider variant="middle"/>
           { requirement.template.config.legal_text && (
            <Markdown options={{
                overrides: {
                    p: {
                        props: {
                            className: 'legal-text',
                        },
                    },
                    table: {
                        props: {
                            className: 'table'
                        },
                    },
                    thead:{
                        props: {
                            className: 'table-head'
                        },
                    },
                    th: {
                        props: {
                            className: 'table-th'
                        },
                    },
                    td: {
                        props: {
                            className: 'table-td'
                        },
                    },
                },
            }}>
                {fillParameters(requirement.template.config.legal_text)}
            </Markdown>
           )}
            <div className="signature-wrapper">
                <SignatureCanvas
                    ref={signCanvas}
                    canvasProps={{
                        className: "signature-pad",
                    }}
                    onEnd={checkSignature}
                />
                <Button size="small" onClick={cleanSignature}>
                    Limpiar
                </Button>
           </div>
           <div className="buttons-wrapper">
                <Button component={Link} to="/" variant="contained" className="back">Atrás</Button>
                <Button variant="contained" className="submit" onClick={saveSignature} disabled={isSignatureEmpty || loading}>
                    {loading ? <CircularProgress sx={{color: "white"}} size={18}/> : "Firmar"}
                </Button>
           </div>
        </div>
    )
}
export default withRouter(Signature);