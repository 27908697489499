import { Divider, Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RequirementCard } from '../../components/requirement-card';
import { RequirementType } from '../../interfaces/requirements';
import NoRequirement from '../no-requirement';

interface PropTypes extends RouteComponentProps {
    requirements: RequirementType[];
    pendings: RequirementType[];
    review: RequirementType[];
}

function Requirements (props: PropTypes) {

    const { requirements, pendings, review} = props;

    const onClickRequirement = (id_requirement: string) => {
        //const pendings = requirements?.filter((r) => (r.status === "PENDING"));
        const existentRequirement = pendings?.find((r: any) => (r.id_requirement === id_requirement)) 
        if (existentRequirement){
            props.history.push(`/requirement/${id_requirement}`);
        }
    }
    
    return (
        <>
            { pendings && pendings.length > 0 && (
                <Box sx={{
                    paddingX: "16px"
                }}>
                    <Box sx={{marginBottom: "16px"}}>
                        <Typography variant="h6" style={{fontWeight: "bold"}}>Requerimientos pendientes:</Typography>
                        <Divider style={{width: "100%"}}/>
                    </Box>

                    <Box sx={{marginBottom: "32px", width: "100%"}}>
                        {pendings.map((r: RequirementType) => (<RequirementCard requirement={r} onClick={onClickRequirement} key={r.id_requirement}/>))}
                    </Box>
                </Box>)
            }
            {pendings?.length === 0  && <NoRequirement/>}
            { review && review.length > 0 && (
                <Box sx={{
                    paddingX: "16px",
                    paddingBottom: "24px"
                }}>
                    <Box sx={{marginBottom: "16px"}}>
                        <Typography variant="h6">En revisión:</Typography>
                        <Divider style={{width: "100%"}}/>
                    </Box>

                    <Box sx={{marginBottom: "32px", width: "100%"}}>
                        {requirements.filter((r: RequirementType) => r.status === "REVIEW").map((r: RequirementType) => (<RequirementCard requirement={r} onClick={onClickRequirement} key={r.id_requirement}/>))}
                    </Box>
                </Box>
            )}
        </>
    )
}

export default withRouter(Requirements)