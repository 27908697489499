import React, { useContext, useState } from 'react'
import Markdown from 'markdown-to-jsx'
import { RequirementType } from '../../../interfaces/requirements'
import Dropzone from 'react-dropzone';
import { FilePreview } from '../../../ui/file-preview';
import { Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import './index.scss';
import { CircularProgress } from '@mui/material';
import { RequirementsContext } from '../../../context/requirements/RequirementsContext';
import { useSnackbar } from 'notistack';

interface PropTypes extends RouteComponentProps{
    requirement: RequirementType;
}

interface UploadFileType {
    attachments: File[],
    data: any
}

const FileUpload = ({requirement, history}: PropTypes) => {
    const schema= requirement.template.config?.schema;
    const uischema= requirement.template.config?.ui_schema;
    const { enqueueSnackbar } = useSnackbar()

    const {loading, submitRequirement} = useContext(RequirementsContext);
    
    const [files, setFiles] = useState<File[]>([]);
    const [maxFiles, setMaxFiles] = useState<number | undefined>(requirement.template.config.max_quantity);
    const [data, setData] = useState();
    const [errors, setErrors] = useState<any>();
    const [displayErrors, setDisplayErrors] = useState(false)
    
    const mimeTypes = requirement.template.config.supported_file_extensions;

    const handleDrop = (acceptedFiles: File[]) => { 
        if (maxFiles !== undefined){
            setMaxFiles(maxFiles-acceptedFiles.length);
        }
        if (maxFiles !== 0){
            setFiles([...files, ...acceptedFiles]);
        }
    };

    const getAcceptedTypesText = () => {
        const extensions = mimeTypes?.map((e)=> (e.split('/')[1]));
        const acceptedExtensions= extensions?.slice(0, -1).join(',')+' and '+ extensions?.slice(-1);
        return (`Only ${acceptedExtensions} will be accepted.`);
    }

    const getMaxFilesText = () => {
        if(requirement.template.config.max_quantity) {
            return (` Maximum of ${requirement.template.config.max_quantity} files.`);
        }
        return "";
    }

    const handleDataChange = ({ data, errors }: any) => {
        setData(data);
        setErrors(errors);
    }

    const saveFiles = async () => {
        if (files.length===0){
            return;
        };
        if (schema && errors?.length !== 0){
            setDisplayErrors(true);
            return;
        }
        const formData = new FormData()
        for(let i=0; i< files.length; i++){
            formData.append("attachments", files[i]);
        }
        data && formData.append("data", JSON.stringify(data))
        const success = await submitRequirement(requirement, formData)
        if(success) {
            enqueueSnackbar("Requerimiento completado con éxito", {
                variant: "success"
            })
            history.push('/')
        }
    };

    const closeRequirement = () => (history.push('/'));

    return (
        <div className="file-upload">
            { requirement.template.instructions &&(
            <Markdown>
                {requirement.template.instructions}
            </Markdown>
           )}
           { schema && uischema && (
                <JsonForms
                    uischema={uischema}
                    schema={schema}
                    data={data}
                    renderers={materialRenderers}
                    cells={materialCells}
                    onChange={handleDataChange}
                    validationMode={displayErrors? "ValidateAndShow" :"ValidateAndHide"}
                />
            )}
            <Dropzone
                onDrop={handleDrop}
                accept={mimeTypes}
                maxFiles={maxFiles}
                >
                {({getRootProps, getInputProps}) => (
                    <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>Arrastre y suelte algunos archivos aquí, o haga click para seleccionarlos manualmente.</p>
                        {
                            mimeTypes && (
                                <em>{getAcceptedTypesText()}{getMaxFilesText()}</em>
                            )
                        }
                    </div>
                    </section>
                )}
            </Dropzone>
            <div className="file-container">
                {files.length > 0 && files.map((f, index) => (
                    <FilePreview key={index} file={f}/>
                )) }
            </div>
            <div className="buttons-wrapper">
                <Button variant="contained" className="back" onClick={closeRequirement}>Atrás</Button>
                <Button variant="contained" className="submit" onClick={saveFiles} disabled={files.length===0} >
                    {loading ? <CircularProgress sx={{color: "white"}} size={18}/> : "Enviar"}
                </Button>
           </div>
        </div>
    )
}

export default withRouter(FileUpload);
