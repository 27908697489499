import { LinearProgress } from '@mui/material';
import React from 'react'
import { LoadingBar } from '../../ui/loading-bar';
import './index.scss';

export const Loading = () => {
    return (
        <div className="pages-loading">
            <div>
                <div  className="loading-label">
                    Cargando...
                </div>
                <LinearProgress/>
            </div>
        </div>
    )
}
