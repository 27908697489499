import { Typography } from '@material-ui/core'
import { MailOutline, PhoneOutlined, PublicOutlined } from '@material-ui/icons'
import { Box, Divider } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom'
import FileUpload from '../../components/documents/file-upload'
import Form from '../../components/documents/form'
import FormSignature from '../../components/documents/form-signature'
import Signature from '../../components/documents/signature'
import { RequirementsContext } from '../../context/requirements/RequirementsContext'
import { RequirementType, TemplateTypes } from '../../interfaces/requirements'
import { Loading } from '../loading'
import './index.scss'


interface PropTypes extends RouteComponentProps<any>{
    requirement: RequirementType | undefined
}

type RequirementParams = {
    id_requirement: string;
};

const Requirement = (props: PropTypes) => {
    const { loading, organization, requirements, errorMessage, removeError, getRequirementsResults } = useContext( RequirementsContext );
    const { enqueueSnackbar } = useSnackbar()

    const {requirement} = props

    useEffect( () => {
        window.scrollTo(0, 0)
    }, [])

    /* useEffect(() => {
        if (requirements) {
            const req = requirements.find(r => (r.id_requirement === id_requirement)) || undefined;
            if (requirement?.status === "PENDING" && req?.status !== "PENDING") {
                enqueueSnackbar("Requerimiento completado con éxito", {
                    variant: "success"
                })
                props.history.push('/')
            }
        }
    }, [requirements, requirement]); */

    useEffect(() => {
        if (errorMessage){
            enqueueSnackbar(errorMessage, {
                variant: "error"
            })
            removeError()
        }
    }, [errorMessage]);

    if (!requirement) {
      return (<Loading/>);
    }

    return (
        <div className='requirement-content'>
            <Typography variant="h6" style={{fontWeight: "bold"}}>{requirement.template.name}</Typography>
            {
                requirement.template.type === TemplateTypes.FileUpload 
                    && (
                        <FileUpload requirement={requirement} />
                    )
            }
            {
                requirement.template.type === TemplateTypes.Form 
                    && (
                        <Form requirement={requirement}/>
                    )
            }
            {
                requirement.template.type === TemplateTypes.Signature 
                    && (
                        <Signature requirement={requirement}/>
                    )
            }

            {
                requirement.template.type === TemplateTypes.FormSignature 
                    && (
                        <FormSignature requirement={requirement}/>
                    )
            }
        </div>
    )
}

export default withRouter(Requirement);