import React, { ReactElement } from 'react';
import { RequirementStatus, RequirementType } from '../../interfaces/requirements';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import './index.scss';
import { AssignmentTurnedInOutlined, CheckOutlined, CloseOutlined, PauseCircleFilledOutlined, TimerOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Desktop, DesktopOrTablet, Mobile } from '../../ui/responsive';
import { Chip } from '@mui/material';
import { getRequirementStatusLabel } from '../../utils';

interface PropTypes{
    requirement: RequirementType;
    onClick: (id_requirement: string) => void;
}

export const getRequirementStatusIcon = (type: string) : ReactElement => {
	switch (type) {
		case RequirementStatus.Completed:
			return <CheckOutlined/>
		case RequirementStatus.Paused:
			return <PauseCircleFilledOutlined/>
		case RequirementStatus.Review:
			return <VisibilityOutlined/>
		case RequirementStatus.Cancelled:
			return <CloseOutlined/>
		case RequirementStatus.Expired:
			return <TimerOutlined/>
		case RequirementStatus.Pending:
			return <TimerOutlined/>
		default:
			return <TimerOutlined/>
	}
}
  
export const RequirementCard = ({requirement, onClick}: PropTypes) => {
    const handleClickRequirement = () => {
        onClick(requirement.id_requirement);
    }

    return (
        <Card className="requirement-card">
            <CardContent className="card-content">
                <Mobile>
                    <div className={`status ${requirement.status.toLowerCase()}`}>
                        {getRequirementStatusIcon(requirement.status)}
                    </div>
                </Mobile>
                <div>
                    {requirement.template.name} {requirement.reference && `- ${requirement.reference}` }
                </div>
                <DesktopOrTablet>
                    <div className={`status ${requirement.status.toLowerCase()}`}>
                        <Chip color={requirement.status === "PENDING" ? "warning" : "default"} icon={getRequirementStatusIcon(requirement.status)} label={getRequirementStatusLabel(requirement.status)} variant="outlined" />
                    </div>
                </DesktopOrTablet>
            </CardContent>
            {
                requirement.status === "PENDING" && (
                    <CardActions>
                        <Button
                            variant="contained" 
                            onClick={handleClickRequirement}
                            disabled={requirement.status !==  "PENDING"}
                            sx={{paddingX: "16px"}}
                            endIcon={
                                <DesktopOrTablet>
                                    <AssignmentTurnedInOutlined />
                                </DesktopOrTablet>
                            }
                        >
                            Completar
                        </Button>
                    </CardActions>
                )
            }
        </Card>

    )
}
