import { CloseOutlined } from '@material-ui/icons';
import { Box, createTheme, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { RequirementsProvider } from './context/requirements/RequirementsContext';
import AppRouter from "./router";

// add action to all snackbars
const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: string | number) => () => { 
  notistackRef && notistackRef.current && notistackRef.current.closeSnackbar(key);
}

const theme = createTheme({
  typography: {
    fontFamily: ["Archivo", "Roboto"].join(','),
    fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
    
  },
  palette: {
    primary: {
      light: '#327bf5',
      main: '#327bf5',
      dark: '#14366f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#63e5c5',
      main: '#40c6c6',
      dark: '#40c6c6',
      contrastText: '#FFF',
    },
  }
})

const App: React.FC = () => {
  
    return (
      <ThemeProvider theme={theme}>
      <RequirementsProvider>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          action={(key) => (
            <IconButton sx={{color:"white"}} onClick={onClickDismiss(key)}>
              <CloseOutlined/>
            </IconButton>
          )}
        >
            <Router>
              <AppRouter />
            </Router>
        </SnackbarProvider>
      </RequirementsProvider>
      </ThemeProvider>
    );
};

export default App;