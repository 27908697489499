export interface RequirementsResponse {
    organization: Organization;
    contact: ContactInfo;
    requirements: RequirementType[];
}

export interface Organization {
    name:            string;
    logo_url:        string;
    primary_color:   string;
    secondary_color: string;
    contact_info:    ContactInfo;
    contact_email:   string;
    contact_phone:   string;
    contact_website: string;
}

export interface ContactInfo {
    email:          string;
    telefono:       string;
    first_name?:     string;
    middle_name?:   string;
    last_name?:      string;
    id_contact?:     string;
}

export interface RequirementType {
    id_requirement:     string;
    status:             string;
    created_at:         string;
    updated_at:         string;
    template:           Template;
    reference:             string;
    activity:           Activity[];
    contact?:           ContactInfo;
}


export enum RequirementStatus {
    Completed = "COMPLETED",
    Paused = "PAUSED",
    Pending = "PENDING",
    Review = "REVIEW",
    Cancelled = "CANCELLED",
    Expired = "EXPIRED"
}

export interface Activity {
    type:  string;
    date:  string;
    data?: Data;
}

export interface Data {
    contact: string;
    email:   string;
}

export interface Template {
    id:           string;
    name:         string;
    type:         TemplateTypes;
    instructions: string;
    config:       Config;
}

export enum TemplateTypes{
    Form = "FORM",
    Signature = "SIGNATURE",
    FileUpload = "FILE_UPLOAD",
    FormSignature = "FORM_SIGNATURE",
}

export interface Config {
    legal_text?:                string;
    supported_file_extensions?: string[];
    max_quantity?:              number;
    schema?:                    Schema;
    ui_schema?:                 UISchema;
}

export interface Schema {
    type:       string;
    properties: any;
    required?:   string[];
}

/* export interface SchemaProperties {
    name:         Name;
    vegetarian:   Occupation;
    birthDate:    BirthDate;
    nationality:  Nationality;
    personalData: PersonalData;
    occupation:   Occupation;
    postalCode:   PostalCode;
}
 */
export interface BirthDate {
    type:   string;
    format: string;
}

export interface Name {
    type:        string;
    minLength:   number;
    description: string;
}

export interface Nationality {
    type: string;
    enum: string[];
}

export interface Occupation {
    type: string;
}

export interface PersonalData {
    type:       string;
    properties: PersonalDataProperties;
    required:   string[];
}

export interface PersonalDataProperties {
    age:          Age;
    height:       Occupation;
    drivingSkill: DrivingSkill;
}

export interface Age {
    type:        string;
    description: string;
}

export interface DrivingSkill {
    type:    string;
    maximum: number;
    minimum: number;
    default: number;
}

export interface PostalCode {
    type:      string;
    maxLength: number;
}

export interface UISchema {
    type:     string;
    elements: UISchemaElement[];
}

export interface UISchemaElement {
    type:      string;
    elements?: ElementElementClass[];
    text?:     string;
}

export interface ElementElementClass {
    type:        string;
    scope:       string;
    suggestion?: string[];
}