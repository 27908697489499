import React from 'react';
import { FileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon';
import "./index.scss";

interface PropTypes{
    file: File;
}

export const FilePreview = ({file} : PropTypes) => {
    const fileExtension = file.name.split('.')[1];

    const getStyles = () => {
        switch(fileExtension) {
            case "pdf":
            case "txt":
            case "png":
            case "jpg":
            case "jpeg": {
                return defaultStyles[fileExtension];
            }
            case "xls":
            case "xlsx":
            case "doc":
            case "docx": {
                return {...defaultStyles[fileExtension] , color: undefined, foldColor: undefined};

            }
            default: {
                return undefined;
            }
        }
    }

    return (
        <div className="ui-file-preview">
            <FileIcon extension={fileExtension} {...getStyles()}/>
            <div>
                {file.name}
            </div>
        </div>
    )
}
