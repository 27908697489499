import FormData from "form-data";
import { createContext, useEffect, useReducer } from "react";
import { useHistory } from "react-router";
import api from "../../api";
import { Organization, RequirementType, RequirementsResponse, TemplateTypes, ContactInfo } from "../../interfaces/requirements";
import { RequirementsReducer } from "./RequirementsReducer";
import { createBrowserHistory } from "history";

type RequirementsContextProps = {
    requirements?: RequirementType[];
    pendings?: RequirementType[];
    review?: RequirementType[];
    organization?: Organization;
    contact?: ContactInfo;
    errorMessage?: string;
    loading?: boolean;
    removeError: () => void;
    cleanRequirements: () => void;
    getRequirementsResults: () => void;
    submitRequirement: (requirement: RequirementType, data: any) => Promise<boolean>
}

const requirementsInitialState = {
    errorMessage: undefined,
    loading: false,
    requirements: undefined,
    pendings: undefined,
    review: undefined,
    organization: undefined,
    contact: undefined,
} 

export const RequirementsContext = createContext({} as RequirementsContextProps);

export const RequirementsProvider = ({children}: any) => {
    const history = createBrowserHistory();
    const [state, dispatch] = useReducer(RequirementsReducer, requirementsInitialState);
    const getRequirementsResults = async () => {
        try {
            const resp = await api.get<RequirementsResponse>(`/public/requirements`);
            const reqs = resp.data.requirements;
            const org = resp.data.organization;
            const contact = resp.data.contact;
            const pendingCount = reqs.filter(r => r.status === "PENDING").length
            document.title = `${pendingCount ? `(${pendingCount})` : ""} Requerimientos de ${org.name} by celeri`

            const pendings = reqs.filter((r) => r.status === "PENDING");
            const inReview = reqs.filter((r) => r.status === "REVIEW");

            dispatch({
                type: 'setRequirementsPending',
                payload: pendings || undefined,
            });
            dispatch({
                type: 'setRequirementsReview',
                payload: inReview || undefined,
            });
            dispatch({
                type: 'setRequirements',
                payload: reqs || undefined,
            });
            dispatch({
                type: 'setOrganization',
                payload: org || undefined,
            });
            dispatch({
                type: 'setContact',
                payload: contact || undefined,
            });
        } catch (error: any) {
            console.log(error?.response?.data?.message)
            cleanRequirements();
            dispatch({
                type: 'addError',
                payload: error?.response?.data?.message || 'Lo sentimos. Ha habido un error',
            });
        }
    };

    const submitRequirement = async (requirement: RequirementType, data: FormData) => {
        try {
            dispatch({type: 'setLoading', payload: true});
            const resp = await api.post(
                `/public/requirements/${requirement.id_requirement}/submissions`, 
                data)
            await getRequirementsResults()
            dispatch({type: 'setLoading', payload: false});
            return true
        } catch (error: any) {
            console.log(error, error.response)
            await dispatch({
                type: 'addError',
                payload: error?.response?.data?.message || "Hubo un error, por favor intenta más tarde",
            });
            await dispatch({type: 'setLoading', payload: false});
            return false
        }
    }

    const removeError = async () => {
        await dispatch({type: 'removeError'});
    };

    const cleanRequirements = () => {
        dispatch({type: 'cleanRequirements'});
    };

    return(
        <RequirementsContext.Provider value={{
            ...state,
            submitRequirement,
            cleanRequirements,
            getRequirementsResults,
            removeError,
        }}>
            {children}
        </RequirementsContext.Provider>
    )
}