import { Icon, Typography } from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';
import { CircularProgress, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Box, minWidth } from '@mui/system';
import React, { useState } from 'react';
import api from '../../api';
import './index.scss';

interface PropTypes{
    isUserExpired: boolean;
}
  
export const AuthenticationError = (props: PropTypes) => {

    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState()

    const handleSubmit = async (email: string) => {
        setLoading(true)
        try {
            const r = await api.post('/auth/retry', {email})
            setLoading(false)
            setResponse(r.data)
        } catch (e: any) {
            console.log(e)
            setLoading(false)
            setResponse(e?.response?.data)
        }
    }

    return (
        <Card className="error-card">
            <CardContent sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                margin: "32px"
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    marginBottom: "16px"
                }}>
                    <WarningOutlined style={{marginRight: "8px"}}/>
                    <Typography variant="h5">
                        {props.isUserExpired ? "Tu sesión ha finalizado" : "No pudimos validar la sesión"}
                    </Typography>
                </Box>
                {!props.isUserExpired &&
                    <Typography variant="body1">
                        Comunicate con quien te haya pedido documentación
                    </Typography>
                }
            </CardContent>
            {props.isUserExpired && 
                <CardActions sx={{
                    marginBottom: "24px"
                }}>
                    <Box sx={{
                        marginTop: "opx",
                        marginBottom: "16px",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                    }}>Ingresá tu email para que te enviemos un nuevo link de acceso.</Box>
                    <Box sx={{
                        color:"white",
                        display:"flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "spaces-between",
                        width: "80%",
                        minWidth: "300px"
                    }}>
                        <TextField
                            color="warning"
                            sx={{
                                marginRight: "8px",
                                color: "white"
                            }}
                            inputProps={{
                                style: {
                                    color: "white"
                                }
                            }}
                            fullWidth
                            type="email"
                            label="Email"
                            placeholder="hola@example.com"
                            variant="outlined"
                            value={email}
                            onChange={(ev) => setEmail(ev.target.value)}
                        />
                        <Button
                            disabled={!email || loading}
                            onClick={ev => handleSubmit(email)}
                            variant="outlined"
                            sx={{
                                color: "white"
                            }}
                        >
                            {loading ? <CircularProgress sx={{color: "white"}} size={16}/> : "Enviar"}
                        </Button>
                    </Box>
                    {response && 
                        <Box sx={{
                            marginTop: "16px",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "bold",
                        }}>Revisa tu casilla de mail.</Box>
                    }
                </CardActions>
            }
        </Card>

    )
}
