import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Requirement from "../pages/requirement";
import { Principal } from "../pages/principal";
import PrivateRoute from "./private-route/private-route";
import { RequirementsContext } from "../context/requirements/RequirementsContext";
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { ErrorMessage } from "../pages/error-message";

const AppRouter = (props: RouteComponentProps) => {

        
    return (
        <>
            <Switch>
                <PrivateRoute
                    exact
                    path="/"
                    component={Principal}
                />
                <PrivateRoute
                    path="/requirement/:id_requirement"
                    component={Principal}
                />
                <Route
                    path="/error-message"
                    component={ErrorMessage}
                />
            </Switch>
        </>
    );
};

export default withRouter(AppRouter)