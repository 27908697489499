import React, { LegacyRef, MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import Markdown from 'markdown-to-jsx';
import { RequirementType } from '../../../interfaces/requirements';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import './index.scss';
import api from '../../../api';
import { RequirementsContext } from '../../../context/requirements/RequirementsContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';

interface PropTypes extends RouteComponentProps{
    requirement: RequirementType;
}

const FormSignature = ({history, requirement}: PropTypes) => {
    const schema= requirement.template.config.schema;
    const uischema= requirement.template.config.ui_schema;
    const [step, setStep] = useState("form");
    const [data, setData] = useState();
    const [errors, setErrors] = useState<any>();
    const [displayErrors, setDisplayErrors] = useState(false)
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true)
    const [legalText, setLegalText] = useState("")
    const signCanvas = useRef() as MutableRefObject<any>;
    const {errorMessage, loading, submitRequirement} = useContext(RequirementsContext);
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (errors?.length > 1 ) {
            setDisabledSubmit(true)
            return;
        } else {
            setDisabledSubmit(false);
        }
    }, [errors])

    const handleDataChange = ({ data, errors }: any) => {
        setData(data);
        setErrors(errors);
        if(errors?.length > 0) setDisplayErrors(true)
    }

    const submitForm = async () => {
        if (errors?.length !== 0) {
            setDisplayErrors(true);
            return;
        }

        setStep("loading")

        const getLegalText = await api.post(`/public/requirements/${requirement.id_requirement}/generate_legal_text`, {form: data})
        
        setLegalText(getLegalText.data.legal_text)
        setStep("legal_text")
    }

    const cleanSignature = () => { 
        signCanvas.current.clear();
        if (signCanvas.current.isEmpty()){
            setIsSignatureEmpty(true);
        };
    };

    const saveSignature = async () => {
        if (signCanvas.current.isEmpty()){
            return;
        };
        const info = {
            signature: signCanvas.current.toDataURL(),
            form: data
        }
        const formData = new FormData()
        await signCanvas.current.getCanvas().toBlob(async (blob: any) => {
            formData.append('attachments', blob, 'signature.png')
            // POST to server with your preferred requests library
            info && formData.append("data", JSON.stringify(info))

            const success = await submitRequirement(requirement, formData)
            console.log(success)
            if(success) {
                enqueueSnackbar("Requerimiento completado con éxito", {
                    variant: "success"
                })
                history.push('/')
            }
        })
    };

    const checkSignature = () => {
        if (signCanvas.current.isEmpty()){
            setIsSignatureEmpty(true);
        }
        else {
            setIsSignatureEmpty(false);
        }
    };

    return (
        <div className="form-signature">
            { requirement.template.instructions &&(
            <Markdown>
                {requirement.template.instructions}
            </Markdown>
           )}
           <Divider variant="middle"/>
           
           { (step === "form" || step === "loading") && schema && uischema && (
                <JsonForms
                    uischema={uischema}
                    schema={schema}
                    data={data}
                    renderers={materialRenderers}
                    cells={materialCells}
                    onChange={handleDataChange}
                    validationMode={displayErrors? "ValidateAndShow" : "ValidateAndHide"}
                />
            )}

            {step === "loading" && <Backdrop open={true}><CircularProgress color="primary" /></Backdrop>}

            { step === "legal_text" && legalText && (
                <>
                    <Markdown options={{
                        overrides: {
                            p: {
                                props: {
                                    className: 'legal-text',
                                },
                            },
                        },
                    }}>
                        {legalText}
                    </Markdown>
                    <div className="form-signature-wrapper">
                        <SignatureCanvas
                            ref={signCanvas}
                            canvasProps={{
                                className: "form-signature-pad",
                            }}
                            onEnd={checkSignature}
                        />
                        <Button size="small" onClick={cleanSignature}>
                            Limpiar
                        </Button>
                    </div>
                </>
            )}

           <div className="buttons-wrapper">
                {step === "legal_text" && <Button onClick={() => setStep("form")} variant="contained" className="back">Atrás</Button>}
                {step === "form" && <Button component={Link} to="/" variant="contained" className="back">Atrás</Button>}
                <Button variant="contained" className="submit" onClick={step === "form" ? submitForm : saveSignature} disabled={step === "loading" || (step === "legal_text" && isSignatureEmpty) || loading}>
                    {loading ? <CircularProgress sx={{color: "white"}} size={18}/> : step === "form" ? "Completar formulario" : "Firmar"}
                </Button>
           </div>
        </div>
    )
}
export default withRouter(FormSignature);