import _ from "lodash";
import moment from "moment";
import React, { ReactElement } from "react";
import { RequirementStatus } from "../interfaces/requirements";
import { TimerOutlined } from '@material-ui/icons';

export const difference = (newObject: any, baseObject: any) => {
	function changes(newObject: any, baseObject: any) {
			return _.transform(newObject, function(result:any, value:any, key:any) {
					if (!_.isEqual(value, baseObject[key])) {
							result[key] = (_.isObject(value) && _.isObject(baseObject[key])) ? changes(value, baseObject[key]) : value;
					}
			});
	}

	const rval = changes(newObject, baseObject);
	let lval = changes(baseObject, newObject);
	return _.assign(lval, rval);
}

export const isArray = function(a: any) {
    return (!!a) && (a.constructor === Array);
};

export const getRequirementStatusLabel = (type: string) : string => {
	switch (type) {
		case RequirementStatus.Completed:
			return "Completado"
		case RequirementStatus.Paused:
			return "Pausado"
		case RequirementStatus.Review:
			return "En revisión"
		case RequirementStatus.Cancelled:
			return "Cancelado"
		case RequirementStatus.Expired:
			return "Expirado"
		case RequirementStatus.Pending:
			return "Pendiente"
		default:
			return "Sin clasificar"
	}
}

export const getFormattedDate = (date: Date ) => (moment(date.toString()).format("DD/MM/YYYY"));