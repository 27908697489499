import React from 'react';
import { withRouter } from 'react-router';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CheckCircle} from '@mui/icons-material';
import './index.scss'

const NoRequirement = () => {
    return(
        <div className='norequirement-card-container'>
            <Card sx={{ minWidth: 275 }}>
                <CardContent>                    
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <div className='norequirement-card-title'>
                            <p className='norequirement-card'>¡Ya estás al día!</p>
                            <CheckCircle color="success"/>                        
                        </div>                        
                        <p className='norequirement-card'>Muchas gracias por tu colaboración, si necesitamos algo más, nos comunicaremos.</p>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}
export default withRouter(NoRequirement);