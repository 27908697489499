import { Organization, RequirementType, RequirementsResponse, ContactInfo } from "../../interfaces/requirements"

export interface RequirementsState {
    errorMessage?: string;
    requirements?: RequirementType[];
    pendings?: RequirementType[];
    review?: RequirementType[];
    organization?: Organization;
    contact?: ContactInfo;
    loading?: boolean;
};

type RequirementsActions =
    | { type: 'setRequirements', payload: RequirementType[] | undefined }
    | { type: 'setRequirementsPending', payload: RequirementType[] | undefined }
    | { type: 'setRequirementsReview', payload: RequirementType[] | undefined }
    | { type: 'setOrganization', payload: Organization | undefined }
    | { type: 'setContact', payload: ContactInfo | undefined }
    | { type: 'updateRequirement', payload: RequirementType }
    | { type: 'addError', payload: string }
    | { type: 'setLoading', payload: boolean }
    | { type: 'cleanRequirements'}
    | { type: 'removeError'}

export const RequirementsReducer = ( state: RequirementsState, action: RequirementsActions): RequirementsState => {
    switch (action.type) {
        case 'addError':
            return {
                ...state,
                errorMessage: action.payload,
            }  
        case 'setLoading':
            return {
                ...state,
                loading: action.payload,
            }            
        case 'removeError':
            return {
                ...state,
                errorMessage: undefined,
            }
        case 'setRequirements':
            return {
                ...state,
                requirements: action.payload,
            }
        case 'setRequirementsPending':
            return {
                ...state,
                pendings: action.payload,
            }
        case 'setRequirementsReview':
            return {
                ...state,
                review: action.payload,
            }
        case 'updateRequirement':
            return {
                ...state,
                requirements: state.requirements?.map(r => {
                    return action.payload.id_requirement === r.id_requirement ? action.payload : r
                }),
            }
        case 'setOrganization':
            return {
                ...state,
                organization: action.payload,
            }
        case 'setContact':
            return {
                ...state,
                contact: action.payload,
            }
        case 'cleanRequirements':
            return {
                ...state,
                requirements: undefined,
            } 
        default:
            return state;
    }
}