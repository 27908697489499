import { useMediaQuery } from 'react-responsive'

export interface DeviceMediaQuery {
    minWidth?: number;
    maxWidth?: number;
};

export const DesktopDevice: DeviceMediaQuery = {
    minWidth: 992,
};

export const TabletDevice: DeviceMediaQuery = {
    minWidth: 768,
    maxWidth: 991,
};

export const MobileDevice: DeviceMediaQuery = {
    maxWidth: 767,
};

export const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: DesktopDevice.minWidth });
  return isDesktop ? children : null
}

export const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: TabletDevice.minWidth, maxWidth: TabletDevice.maxWidth });
  return isTablet ? children : null
}

export const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: MobileDevice.maxWidth });
  return isMobile ? children : null
}
export const DesktopOrTablet = ({ children }: any) => {
  const isDesktopOrTablet = useMediaQuery({ minWidth: TabletDevice.minWidth })
  return isDesktopOrTablet ? children : null
}
