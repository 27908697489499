import React, { useContext, useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { RequirementType } from '../../../interfaces/requirements';
import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import './index.scss';
import api from '../../../api';
import { RequirementsContext } from '../../../context/requirements/RequirementsContext';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

interface PropTypes extends RouteComponentProps{
    requirement: RequirementType;
}

const Form = ({requirement, history}: PropTypes) => {
    const schema= requirement.template.config.schema;
    const uischema= requirement.template.config.ui_schema;
    const {errorMessage, loading, submitRequirement} = useContext(RequirementsContext);
    const [data, setData] = useState();
    const [errors, setErrors] = useState<any>();
    const [displayErrors, setDisplayErrors] = useState(false)
    const [disabledSubmit, setDisabledSubmit] = useState(true);

    const { enqueueSnackbar } = useSnackbar()
    const closeRequirement = () => (history.push('/'));

    useEffect(() => {
        if (errors?.length > 1 ) {
            setDisabledSubmit(true)
            return;
        } else {
            setDisabledSubmit(false);
        }
    }, [errors])

    const handleDataChange = ({ data, errors }: any) => {
        setData(data);
        setErrors(errors);
        if(errors?.length > 0) setDisplayErrors(true)
    }

    const submitForm = async () => {
        if (errors?.length !== 0) {
            setDisplayErrors(true);
            return;
        }

        const success = await submitRequirement(requirement, data)
        if(success) {
            enqueueSnackbar("Requerimiento completado con éxito", {
                variant: "success"
            })
            history.push('/')
        }
    }

    return (
        <div className="form">
            <div className="form-content">
                { requirement.template.instructions &&(
                    <Markdown>
                        {requirement.template.instructions}
                    </Markdown>
                )}
                { schema && uischema && (
                    <JsonForms
                        uischema={uischema}
                        schema={schema}
                        data={data}
                        renderers={materialRenderers}
                        cells={materialCells}
                        onChange={handleDataChange}
                        validationMode={displayErrors? "ValidateAndShow" : "ValidateAndHide"}
                    />
                )}
            </div>
            <div className="buttons-wrapper">
                <Button variant="contained" className="back" onClick={closeRequirement}>Atrás</Button>
                <Button variant="contained" disabled={disabledSubmit || loading} className="submit" onClick={submitForm}>
                    {loading ? <CircularProgress sx={{color: "white"}} size={18}/> : "Completar"}
                </Button>
           </div>  
        </div>
    )
}

export default withRouter(Form);