import { Icon, Typography } from '@material-ui/core';
import { InfoOutlined, WarningOutlined } from '@material-ui/icons';
import { CircularProgress, IconButton, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Box, minWidth } from '@mui/system';
import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../api';
import { RequirementsContext } from '../../context/requirements/RequirementsContext';
import './index.scss';
  
export const ErrorMessage = (props: RouteComponentProps) => {

    const { errorMessage, removeError } = useContext( RequirementsContext );

    const [showDetail, setShowDetail] = useState(false)

    if (!errorMessage) {
        props.history.push('/')
    }

    return (
        <Box sx={{
            display: "grid",
            placeItems: "center",
            alignContent: "center",
            width: "100%",
            marginTop: "48px"
        }}>
            <Typography variant="h3" color="primary" style={{fontWeight: "bold"}}>Uups...</Typography>
            <Box sx={{maxWidth: {xs: 300, md: 600}, marginTop: "16px", display: "flex", flexDirection: "row", alignItems: "center" ,color:"grey", fontWeight: "semi-bold"}}>
                <Typography variant="h6">Tuvimos un problema! Por favor intenta más tarde.</Typography>
                <IconButton sx={{marginLeft: "16px"}}onClick={() => setShowDetail(!showDetail)} >
                    <InfoOutlined style={{color: "grey"}}/>
                </IconButton>
            </Box>
            {showDetail &&
                <Typography variant='body1' style={{color: "grey"}}>{errorMessage}</Typography>
            }
            <Button onClick={() => {
                removeError()
                props.history.push('/')
            }} variant="contained" color="secondary" sx={{marginTop: "24px", padding: "8px", paddingX: "16px"}}>
                Volver a intentar
            </Button>
        </Box>

    )
}
