import { Typography } from '@material-ui/core';
import { MailOutline, PhoneOutlined, PublicOutlined } from '@material-ui/icons';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RequirementsContext } from '../../context/requirements/RequirementsContext';
import { RequirementType } from '../../interfaces/requirements';
import { Loading } from '../loading';
import Requirement from '../requirement';
import Requirements from '../requirements';
import NoRequirement from '../no-requirement'
import './index.scss';

type RequirementParams = {
    id_requirement: string;
};

export const Principal = (props: any) => {

    const { contact, requirements, pendings, review, organization, errorMessage, getRequirementsResults } = useContext( RequirementsContext );
    const [requirement, setRequirement] = useState<RequirementType | undefined>(undefined);

    const { id_requirement } = useParams<RequirementParams>();

    console.log("id_requirement",id_requirement)
    console.log("requirements",requirements)

    const manageOnePendingRequirement = () => {
        if (requirements)  {
            if (pendings && pendings.length === 1) {
                props.history.push(`/requirement/${pendings[0].id_requirement}`)
            }
        }
    }

    useEffect( () => {
        window.scrollTo(0, 0)
    }, [])

    useEffect( () => {
        window.scrollTo(0, 0)
    }, [id_requirement])

    useEffect(() => {
        if (!requirements) {
            getRequirementsResults();
        }
    }, []);

    useEffect(() => {
        if (requirements && id_requirement) {
            const req = requirements.find(r => r.id_requirement === id_requirement) || undefined;
            if (req) {
                setRequirement(req);
            } else {
                props.history.push('/error-404');
            }
        }
    }, [requirements, id_requirement]);

    useEffect(() => {
        if (errorMessage && !id_requirement) {
            props.history.push('/error-message');
        }
    }, [errorMessage]);

    if (!requirements || !organization) {
        return <Loading />;
    }
    
    return (
        <div className="pages-principal">
            <div className="logo">
                <Typography className="user-greeting" variant="h6" color="primary">Hola {contact?.first_name} {contact?.middle_name} {contact?.last_name}! </Typography>
                <img src={organization.logo_url || "holder.js/171x180" }/>
            </div>
            {!id_requirement && <Requirements requirements={requirements} pendings={pendings || []} review={review || []} />}
            {id_requirement && <Requirement requirement={requirement}/>}
            <Box className="footer">
                <Box className="child-box">
                    <Typography className="organization-name">{organization?.name}</Typography>
                </Box>
                <div className="wrap-box">
                    <Box className="child-wrap child-box ">
                        <MailOutline className="icon-footer"/> <Typography className='hash' component="a" href={"mailto:" + organization?.contact_email}>{organization?.contact_email}</Typography>
                    </Box>
                    <Box className="child-wrap child-box ">
                    <PhoneOutlined className="icon-footer"/> <Typography className='hash' component="a" href={"phone:" + organization?.contact_phone} style={{textDecoration: "none", width: "max-content"}}>{organization?.contact_phone}</Typography>
                    </Box>
                </div>
                <Box className="child-box">
                    <PublicOutlined className="icon-footer"/> <Typography className='hash' component="a" href={organization?.contact_website}>{organization?.contact_website}</Typography>
                </Box> 
            </Box>
        </div>
    )
}